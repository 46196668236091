<template>
  <Box>
  
    <SpacerRow />
  
    <Row>
      <Column :width="4" />
      <Column :width="7" >
        <Box>
          <ErrorRow :error="error" />
          <SuccessRow :message="message" />
        </Box>
      </Column>
      <Column :width="4" />
    </Row>
    
    <TitleRow :left="4" :right="4"> {{ MC.Sign.Out.Title.value() }} </TitleRow>
    
    <Row>
      <Column :width="4" />
      <Column :width="7" :showBorder="true">
        <Spacer />
        <Value align="centre" fontSize="large"> {{ MC.Sign.Out.Message.value() }} </Value>
        <Spacer />
      </Column>
      <Column :width="4" />
    </Row>
    
    <Row v-if="!isLoading">
      <Column :width="4" />
      <Column :width="7" >
        <Button :go="true" v-on:click="signOut" :disabled="isSignInDisabled"> {{ MC.Sign.Out.SignOut.value() }} </Button>
      </Column>
      <Column :width="4" />
    </Row>
    
    <LoadingRow :left="4" :right="4" :showBorder="true" v-if="isLoading">{{ loadingMessage }}</LoadingRow>

  </Box>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import UserEvents from '@/domain/model/user/UserEvents.js';
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  
import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
//import Label from "@/portals/shared/library/label/Label.vue";
import Value from "@/portals/shared/library/value/Value.vue";
import Button from "@/portals/shared/library/button/Button.vue";

export default {
  name: 'signout',
  components: {
    Button,
    ErrorRow,
    SuccessRow,
    Box, Row, Column,
    SpacerRow, TitleRow, LoadingRow,
    Value, Spacer,

  },
  data() {
    return {
      isSignInDisabled: false,
      message: null,
      error: null,
      
      isLoading: true,
      loadingMessage: null,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
    };
  },
  computed: {
    ...mapGetters([
      'auth_user',
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
      ]),
    companyId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),
    ...mapMutations([
                     'authClear',
                     ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    signOut: function() {
      if (this.auth_connected) {
        var event = this.domain.events().userSignOut();
        event.send(this.listener);
        this.doneSignOut();
      }
    },
    listener: function(event) {
      if (event.is(UserEvents.SignOut.Names.Response)) {
        if (event.error()) {
          this.error = event.error();
        } else {
          this.doneSignOut()
        }
      }
    },
    doneSignOut: function() {
      this.message = "Success!";
      this.authClear();
      this.$socket.close();
      var cookie = this.$cookie.get("readychek.ca");
      if (cookie) {
        cookie = JSON.parse(cookie);
        cookie.auto = false;
        cookie['Company'] = "";
        var cookieString = JSON.stringify(cookie);
        this.$cookie.set("readychek.ca", cookieString, 365);
        window.localStorage.readyChek = cookieString;
      }

      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });      
    }
  }  
}
</script>